import * as React from 'react'
import Layout from '../../../layout'
import LeaderboardsPage from '../../../pagesComponents/leaderboards'

const Leaderboards = ({ groupId }) => {
	return (
		<Layout title="Group Leaderboard">
			<LeaderboardsPage groupId={groupId} hideVenue />
		</Layout>
	)
}

export default Leaderboards
